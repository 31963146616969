import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useModalStore, withErrorBoundary } from '@navi-app/utils';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ModalSubsriptionFinished = () => {
  const { t } = useTranslation('modal-subscription');

  const { modalName, closeModal, modalData } = useModalStore();

  return (
    <Modal
      isOpen={modalName === 'modalSubscriptionFinished'}
      onClose={closeModal}
      overrides={{
        Dialog: {
          style: ({ $theme }) => ({
            width: '600px',
          }),
        },
        Root: {
          style: ({ $theme }) => ({
            zIndex: '9999',
          }),
        },
      }}
    >
      <ModalHeader>
        <HeadingSmall>{t(modalData?.errorMessage?.title)}</HeadingSmall>
      </ModalHeader>
      <ModalBody>
        <div className="bg-[#FFF0EE] p-4 flex gap-4 place-items-center rounded-lg mb-4">
          <InformationCircleIcon width={24} height={24} color="#000000" />
          <div>
            <ParagraphMedium className="!font-medium">
              {t(modalData?.errorMessage?.infoTitle)}
            </ParagraphMedium>
            <ParagraphMedium className="flex flex-wrap">
              {t(modalData?.errorMessage?.infoMessage)}
              <a
                href="mailto:care@mobitech.id"
                className="mx-2 underline text-blue-500"
              >
                care@mobitech.id
              </a>
              {t('modal_subscription_finished.or_whatsapp_on')}{' '}
              <a
                href="https://wa.me/6281113607111"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 underline text-blue-500"
              >
                +62 811-1360-7111
              </a>
            </ParagraphMedium>
          </div>
        </div>
        <ParagraphMedium>
          {t(modalData?.errorMessage?.description)}
        </ParagraphMedium>
      </ModalBody>
    </Modal>
  );
};

export default withErrorBoundary(memo(ModalSubsriptionFinished));
