import { Meta } from '@navi-app/ui';
import { withErrorBoundary } from '@navi-app/utils';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import useCheckArticle from '../../commons/hooks/use-check-article';

const SectionForm = lazy(() => import('./components/section-form'));
const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);

function DemoPage() {
  const { t } = useTranslation(['form']);
  const { isHasArticle, isLoadingArticle } = useCheckArticle();

  const lang = window?.localStorage?.getItem('i18nextLng') || 'id';

  return (
    <>
      <Meta
        key="meta-demo"
        title={t('meta.title')}
        description={t('meta.description')}
        keywords="pengiriman, order, aplikasi logistik"
        openGraph={{
          title: t('meta.title'),
          description: t('meta.description'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          type: 'website',
          url: window?.location?.href || config.BRAND_URL,
        }}
        twitter={{
          card: 'summary_large_image',
          description: t('meta.description'),
          title: t('meta.title'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          url: window?.location?.href || config.BRAND_URL,
        }}
        canonicalLink={`${config.BRAND_URL}/${lang}/demo`}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'SoftwareApplication',
          name: 'Mobitech Demo',
          url: `${config.BRAND_URL}/${lang}/demo`,
          description:
            'Explore the features and capabilities of Mobitech with our interactive demo.',
          applicationCategory: 'BusinessApplication',
          operatingSystem: 'All',
          softwareVersion: '1.0',
          creator: {
            '@type': 'Organization',
            name: 'Mobitech Indonesia',
          },
        }}
        config={config}
      />
      <Suspense>
        <HeaderLanding
          config={layoutConfig}
          isHasArticle={isHasArticle}
          isLoadingArticle={isLoadingArticle}
        />
        <main>
          <SectionForm />
        </main>
      </Suspense>
    </>
  );
}

export default withErrorBoundary(DemoPage, {
  isPage: true,
});
