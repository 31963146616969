import Cookies from 'js-cookie';
import { removeAuthentication } from '../auth-helper';
import { fetchApi, refreshApi } from '../http-request';

type TAdditionalConfig = {
  domainApp: string;
  authUrl: string;
};

type TParamsChangePassword = {
  apiUrl: string;
  payload: {
    oldPassword: string;
    newPassword: string;
    reNewPassword: string;
  };
};

type TParamsApiCheckSubscription = {
  apiUrl: string;
  requestId?: string;
};

type TCookies = {
  token: string | undefined;
  userRoleId: string | undefined;
  customerId: string | undefined;
};

export const authenticationAPI = (
  apiUrl: string,
  additionalConfig?: TAdditionalConfig
) => {
  const getCookies = async (): Promise<TCookies> => {
    return new Promise((resolve) => {
      const token = Cookies.get('authToken');
      const userRoleId = Cookies.get('userRoleId');
      const customerId = Cookies.get('customerId');
      resolve({ token, userRoleId, customerId });
    });
  };

  const initAuth = async () => {
    const { token, userRoleId, customerId } = await getCookies();

    const url =
      !!userRoleId && !!customerId
        ? `${apiUrl}/api/auth/init-authorization?urid=${userRoleId}&cid=${customerId}`
        : `${apiUrl}/api/auth/init-authorization`;

    try {
      const response = await fetchApi(url, {
        headers: {
          'X-Authorization': `Bearer ${token}`,
        },
      });
      const data = response?.data;
      if ([200, 201].indexOf(data.statusCode) === -1) {
        throw new Error(data.message);
      }
      return data?.data || null;
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  };

  const userProfile = async () => {
    const token = Cookies.get('authToken');
    try {
      const response = await fetchApi(`${apiUrl}/api/user/profile`, {
        headers: {
          'X-Authorization': `Bearer ${token}`,
        },
      });
      const data = response?.data;
      if ([200, 201].indexOf(data.statusCode) === -1) {
        throw new Error(data.message);
      }
      return data?.data || null;
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  };

  const logout = async (payload: null) => {
    const token = Cookies.get('authToken');
    try {
      const response = await fetchApi.post(
        `${apiUrl}/api/auth/logout`,
        payload,
        {
          headers: {
            'X-Authorization': `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      if ([200, 201].indexOf(data.statusCode) === -1) {
        throw new Error(data.message);
      }
      return data?.data || null;
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  };

  const refreshToken = async () => {
    const refreshToken = Cookies.get('refreshToken');
    const token = Cookies.get('authToken');
    try {
      const response = await refreshApi.post(
        `${apiUrl}/api/auth/refresh-token`,
        {
          authToken: token,
          refreshToken,
        },
        {
          headers: {
            'X-Authorization': `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      if ([200, 201].indexOf(data.statusCode) === -1) {
        throw new Error(data.message);
      }
      return data?.data || null;
    } catch (error: unknown) {
      removeAuthentication(additionalConfig?.domainApp);
      window.location.href = additionalConfig?.authUrl || '/';
      return Promise.reject(error);
    }
  };

  return {
    initAuth,
    userProfile,
    logout,
    refreshToken,
  };
};

export const changePassword = async ({
  apiUrl,
  payload,
}: TParamsChangePassword) => {
  try {
    const response = await fetchApi.post(
      `${apiUrl}/api/auth/change-password`,
      payload
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const checkSubscriptionMobi = async ({
  apiUrl,
}: TParamsApiCheckSubscription) => {
  try {
    const response = await fetchApi.post(`${apiUrl}/api/mobi/order`);
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const checkSubscriptionOptima = async ({
  apiUrl,
  requestId,
}: TParamsApiCheckSubscription) => {
  try {
    const response = await fetchApi.post(
      `${apiUrl}/api/optima/generate-order-plan`,
      {},
      {
        headers: {
          'X-Request-Id': requestId,
        },
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const checkVehicleQuota = async ({
  apiUrl,
}: TParamsApiCheckSubscription) => {
  try {
    const response = await fetchApi.post(`${apiUrl}/api/fleet/vehicle`);
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const checkSubscriptionStatus = async ({
  apiUrl,
}: TParamsApiCheckSubscription) => {
  try {
    const response = await fetchApi(`${apiUrl}/api/auth/subscription-status`);
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
