/* eslint-disable @typescript-eslint/ban-ts-comment */
import { cleanObject, httpRequest } from '@navi-app/utils';
import config from '../commons/constants/config';

type TPayloadDemo = {
  ipAddress: string;
  fullName: string;
  phone: string;
  email: string;
  companyName: string;
  companySize: string | null;
  demoDate: string | null;
};

type TParamsGetListArticle = {
  limit?: number;
  offset?: number;
  keyword?: string;
  excludeIds?: string;
  status?: string;
};

export const postDemoRequest = async (payload: TPayloadDemo) => {
  try {
    const { ipAddress, ...rest } = payload || {};
    const modifiedInstance = httpRequest.fetchApi;

    // @ts-ignore
    modifiedInstance.defaults.headers = {
      ...modifiedInstance.defaults.headers,
      'X-Api-Key': config.X_API_KEY_DEMO,
      'X-Ip-Addr': ipAddress,
    };

    const response = await modifiedInstance.post('api/demo', rest);
    const data = response.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const validateRecaptcha = async (payload: {
  gCaptchaResponseToken: string;
}) => {
  try {
    const response = await httpRequest.fetchApi.post(
      'api/auth/validate-captcha',
      payload
    );
    const data = response.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

// Sitemap
export const getXmlContent = async () => {
  try {
    const response = await httpRequest.fetchApi('api/sitemap.xml', {
      headers: {
        'Content-Type': 'text/xml',
      },
    });
    const data = response.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Article
export const getListArticle = async (params: TParamsGetListArticle) => {
  try {
    const cleanParams = cleanObject(params);
    const urlParams = new URLSearchParams(cleanParams).toString();

    const response = await httpRequest.fetchApi(`api/wp?${urlParams}`);
    return response.data?.data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDetailArticle = async (articleId: string) => {
  try {
    const response = await httpRequest.fetchApi(`api/wp/${articleId}`);
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDetailArticleByPermaLink = async (permalink: string) => {
  try {
    const response = await httpRequest.fetchApi(
      `api/wp/permalink/${permalink}`
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};
