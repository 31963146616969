import { withErrorBoundary } from '@navi-app/utils';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import {
  DisplaySmall,
  LabelLarge,
  LabelSmall,
  ParagraphSmall,
} from 'baseui/typography';
import { lazy } from 'react';
import { layoutConfig } from '../../commons/constants/layout';
import PlanItem from './components/plan-item';
import useSubscription from './hooks/use-subscription';

const FooterLanding = lazy(
  () => import('../../commons/components/footer-landing')
);
const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);

function SubscriptionPage() {
  const {
    isHasArticle,
    isLoadingArticle,
    handleChangeShowDifferent,
    handleChangeSimulateDiscount,
    isShowDifferent,
    isSimulateDiscount,
    getGrettingMessage,
    price,
    subscriptions,
    t,
    plans,
  } = useSubscription();
  return (
    <>
      <HeaderLanding
        config={layoutConfig}
        isHasArticle={isHasArticle}
        isLoadingArticle={isLoadingArticle}
      />
      <main className="px-[120px] py-6 flex flex-col items-center gap-6">
        <div className="flex flex-col gap-4 items-center">
          <DisplaySmall>{t('title')}</DisplaySmall>
          <LabelLarge color="#A6A6A6" className="text-center">
            {t('subtitle')} <br />
            {t('second_subtitle')}
          </LabelLarge>
        </div>
        <div className="flex items-center justify-center gap-3">
          {/*  */}
          <Checkbox
            checked={isSimulateDiscount}
            checkmarkType={STYLE_TYPE.toggle_round}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  alignItems: 'center',
                  justifyContent: 'center',
                }),
              },
              Toggle: {
                style: ({ $theme }) => ({
                  outline: isSimulateDiscount
                    ? `#1E64DC solid`
                    : `#A6A6A6 solid`,
                  backgroundColor: '#fff',
                }),
              },
              ToggleTrack: {
                style: ({ $theme }) => ({
                  backgroundColor: isSimulateDiscount ? '#1E64DC' : '#A6A6A6',
                }),
              },
            }}
            onChange={handleChangeSimulateDiscount}
          >
            <div className="flex items-center gap-1">
              <ParagraphSmall>{t('subscription_six_month')}</ParagraphSmall>
              <LabelSmall color="#ED6E33">{t('get_discount')}</LabelSmall>
            </div>
          </Checkbox>
        </div>
        <div className="flex items-end mt-4">
          <div className="w-[299px]">
            <div className="flex pb-4 ml-3">
              <Checkbox
                checked={isShowDifferent}
                labelPlacement={LABEL_PLACEMENT.right}
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      alignItems: 'center',
                      justifyContent: 'center',
                    }),
                  },
                }}
                onChange={handleChangeShowDifferent}
              >
                <LabelSmall>{t('show_different_plan')}</LabelSmall>
              </Checkbox>
            </div>
            {subscriptions.map((indicator, index) => (
              <div
                key={index}
                className="p-4 border-b border-solid border-[#E6E9EB] h-[52px]"
              >
                <ParagraphSmall>{indicator.name}</ParagraphSmall>
              </div>
            ))}
          </div>
          {plans.map((plan, index) => (
            <PlanItem
              key={index}
              type={plan.type}
              t={t}
              subscriptions={plan.subscriptions}
              price={price}
              whatsappLink={getGrettingMessage(plan.type)}
            />
          ))}
        </div>
      </main>
      <FooterLanding />
    </>
  );
}

export default withErrorBoundary(SubscriptionPage, {
  isPage: true,
});
