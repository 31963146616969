/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from '@heroicons/react/24/solid';
import { formatDateToLocal, withErrorBoundary } from '@navi-app/utils';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ProgressSteps, Step } from 'baseui/progress-steps';
import {
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import { TFunction } from 'i18next';
import { memo, useState } from 'react';
import Tag from '../lib/tag';

export type DynamicObject = {
  [name: string]: any;
};

type TLocationLogSteps = {
  t: TFunction;
  data: DynamicObject[] | undefined;
  isPhoneSize?: boolean;
  showIconExpand?: boolean;
  showTag?: boolean;
  showLoadDateAndCity?: boolean;
  loadUnloadDate?: DynamicObject;
  titleSmall?: boolean;
  addressXSmall?: boolean;
  customMaxWidth?: string;
};

const IconLoad = () => <ArrowUpCircleIcon className="w-6 h-6" />;
const IconUnload = () => (
  <ArrowDownCircleIcon className="w-6 h-6 text-[#0E1FC1]" />
);

const LocationLogSteps = ({
  t,
  data,
  isPhoneSize,
  showIconExpand,
  showTag,
  showLoadDateAndCity,
  loadUnloadDate,
  titleSmall,
  addressXSmall,
  customMaxWidth,
}: TLocationLogSteps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const origin = data?.[0];
  const destination = data?.[data.length - 1];

  const additionalRoutes = data?.slice(1, -1);

  return (
    <ProgressSteps
      current={data?.length}
      alwaysShowDescription
      overrides={{
        Root: {
          style: {
            maxWidth: customMaxWidth,
            marginBottom: '0px',
            padding: '0px',
            position: 'relative',
            left: '-20px !important',
            marginTop: isPhoneSize ? '8px' : 0,
          },
        },
        IconContainer: { style: { marginTop: '8px' } },
        Tail: { style: { marginTop: '24px', backgroundColor: '#DDDDDD' } },
        Title: {
          style: { padding: '0px', lineHeight: '16px', fontSize: '14px' },
        },
        Description: { style: { marginTop: '8px' } },
      }}
    >
      {/* Origin */}
      <Step
        key={origin?.locationId}
        title={
          <>
            {showTag && (
              <Tag customClass="mb-2" content={t(origin?.activity || '')} />
            )}
            {titleSmall ? (
              <LabelSmall>{origin?.locationName}</LabelSmall>
            ) : (
              <LabelMedium>{origin?.locationName}</LabelMedium>
            )}
          </>
        }
        overrides={{
          Icon: {
            component: origin?.activity === 'load' ? IconLoad : IconUnload,
          },
          IconContainer: {
            style: {
              width: '24px',
              height: '24px',
              margin: '0px 0px 0px 20px',
            },
          },
          Title: {
            style: {
              fontSize: titleSmall ? '14px' : '16px',
              fontWeight: 500,
              padding: 0,
            },
          },
        }}
      >
        {addressXSmall ? (
          <ParagraphXSmall className="text-[#5E5E5E]">
            {origin?.locationAddress}
          </ParagraphXSmall>
        ) : (
          <ParagraphSmall className="text-[#5E5E5E]">
            {origin?.locationAddress}
          </ParagraphSmall>
        )}
        {showLoadDateAndCity && (
          <FlexGrid flexGridColumnCount={2} className="mt-4">
            <FlexGridItem>
              <div>
                <ParagraphXSmall className="!text-[#5E5E5E]">
                  {t('load_estimation')}
                </ParagraphXSmall>
                <ParagraphSmall>
                  {formatDateToLocal(
                    loadUnloadDate?.loadDate || '',
                    'dd MMM yyyy, HH:mm'
                  )}
                </ParagraphSmall>
              </div>
            </FlexGridItem>
            <FlexGridItem>
              <div>
                <ParagraphXSmall className="!text-[#5E5E5E]">
                  {t('city_or_subdistrict')}
                </ParagraphXSmall>
                <ParagraphSmall>{`${origin?.district || ''}, ${
                  origin?.city || ''
                }`}</ParagraphSmall>
              </div>
            </FlexGridItem>
          </FlexGrid>
        )}
        {data && data?.length > 2 && (
          <ParagraphXSmall
            overrides={{ Block: { style: { color: '#276EF1' } } }}
            onClick={() => setIsExpanded(!isExpanded)}
            className={`cursor-pointer ${showIconExpand ? 'flex mt-6' : ''}`}
          >
            {t(isExpanded ? 'close_route_completely' : 'open_route_completely')}
            {showIconExpand && (
              <div>
                {isExpanded ? (
                  <ChevronUpIcon width={20} height={20} />
                ) : (
                  <ChevronDownIcon width={20} height={20} />
                )}
              </div>
            )}
          </ParagraphXSmall>
        )}
      </Step>

      {isExpanded &&
        additionalRoutes?.map((route: DynamicObject) => (
          <Step
            key={route?.locationId}
            title={
              <>
                {showTag && <Tag content={t(route.activity || '')} />}
                {titleSmall ? (
                  <LabelSmall className="mt-2">
                    {route?.locationName}
                  </LabelSmall>
                ) : (
                  <LabelMedium className="mt-2">
                    {route?.locationName}
                  </LabelMedium>
                )}
              </>
            }
            overrides={{
              Icon: {
                component: route?.activity === 'load' ? IconLoad : IconUnload,
              },
              IconContainer: {
                style: {
                  width: '24px',
                  height: '24px',
                  margin: '0px 0px 0px 20px',
                },
              },
              Title: {
                style: {
                  fontSize: titleSmall ? '14px' : '16px',
                  fontWeight: 500,
                  padding: 0,
                  color: '#5E5E5E',
                },
              },
            }}
          >
            {addressXSmall ? (
              <ParagraphXSmall className="text-[#5E5E5E]">
                {route?.locationAddress}
              </ParagraphXSmall>
            ) : (
              <ParagraphSmall className="text-[#5E5E5E]">
                {route?.locationAddress}
              </ParagraphSmall>
            )}
            {showLoadDateAndCity && (
              <FlexGrid flexGridColumnCount={2} className="mt-4">
                <FlexGridItem>
                  <div>
                    <ParagraphXSmall className="!text-[#5E5E5E]">
                      {route?.activity === 'load'
                        ? t('load_estimation')
                        : t('unload_estimation')}
                    </ParagraphXSmall>
                    <ParagraphSmall>
                      {formatDateToLocal(
                        route.eta || '',
                        'dd MMM yyyy, HH:mm'
                      ) || '-'}
                    </ParagraphSmall>
                  </div>
                </FlexGridItem>
                <FlexGridItem>
                  <div>
                    <ParagraphXSmall className="!text-[#5E5E5E]">
                      {t('city_or_subdistrict')}
                    </ParagraphXSmall>
                    <ParagraphSmall>{`${route?.district || ''}, ${
                      route?.city || ''
                    }`}</ParagraphSmall>
                  </div>
                </FlexGridItem>
              </FlexGrid>
            )}
          </Step>
        ))}

      {/* Destination */}
      <Step
        key={destination?.locationId}
        title={
          <>
            {showTag && <Tag content={t(destination?.activity || '')} />}
            {titleSmall ? (
              <LabelSmall className="mt-2">
                {destination?.locationName}
              </LabelSmall>
            ) : (
              <LabelMedium className="mt-2">
                {destination?.locationName}
              </LabelMedium>
            )}
          </>
        }
        overrides={{
          Icon: {
            component: destination?.activity === 'load' ? IconLoad : IconUnload,
          },
          IconContainer: {
            style: {
              width: '24px',
              height: '24px',
              margin: '0px 0px 0px 20px',
            },
          },
          Title: {
            style: {
              fontSize: titleSmall ? '14px' : '16px',
              fontWeight: 500,
              padding: 0,
              color: '#5E5E5E',
            },
          },
        }}
      >
        {addressXSmall ? (
          <ParagraphXSmall className="text-[#5E5E5E]">
            {destination?.locationAddress}
          </ParagraphXSmall>
        ) : (
          <ParagraphSmall className="text-[#5E5E5E]">
            {destination?.locationAddress}
          </ParagraphSmall>
        )}
        {showLoadDateAndCity && (
          <FlexGrid flexGridColumnCount={2} className="mt-4">
            <FlexGridItem>
              <div>
                <ParagraphXSmall className="!text-[#5E5E5E]">
                  {t('unload_estimation')}
                </ParagraphXSmall>
                <ParagraphSmall>
                  {formatDateToLocal(
                    loadUnloadDate?.unloadDate || '',
                    'dd MMM yyyy, HH:mm'
                  )}
                </ParagraphSmall>
              </div>
            </FlexGridItem>
            <FlexGridItem>
              <div>
                <ParagraphXSmall className="!text-[#5E5E5E]">
                  {t('city_or_subdistrict')}
                </ParagraphXSmall>
                <ParagraphSmall>{`${destination?.district || ''}, ${
                  destination?.city || ''
                }`}</ParagraphSmall>
              </div>
            </FlexGridItem>
          </FlexGrid>
        )}
      </Step>
    </ProgressSteps>
  );
};

export default withErrorBoundary(memo(LocationLogSteps));
