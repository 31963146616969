/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Cookies from 'js-cookie';
import { authenticationAPI } from '../api/authentication';
import { removeAuthentication } from '../auth-helper';
import useModalStore from '../stores/use-modal.store';
import { isEmpty } from '../utils';
import { showGlobalAlert } from './alert';

let contentType = null as any;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers = {
    ...config.headers,
    ...(Cookies.get('authToken')
      ? { 'X-Authorization': `Bearer ${Cookies.get('authToken')}` }
      : {}),
  };
  contentType = config.headers['Content-Type'];
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

let refreshTokenPromise = null as any;
const clearPromise = () => (refreshTokenPromise = null);

const onResponseError = async (
  error: AxiosError,
  axiosInstance: AxiosInstance
): Promise<AxiosError> => {
  const originalRequest = error.config;
  const errorDataMessage = error as {
    response: { data: { message: string; data: any } };
    config: { data: any };
  };

  if (
    error.response?.status === 401 &&
    //@ts-ignore
    !originalRequest._retry &&
    //@ts-ignore
    !originalRequest.url?.includes('api/auth/refresh-token')
  ) {
    //@ts-ignore
    originalRequest._retry = true;
    if (Cookies.get('refreshToken')) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = authenticationAPI(
          axiosInstance.defaults.baseURL as string,
          {
            domainApp: axiosInstance.defaults.data['domain-app'] as string,
            authUrl: axiosInstance.defaults.data['auth-url'] as string,
          }
        )
          .refreshToken()
          .finally(clearPromise);
      }

      const resp = await refreshTokenPromise;
      if (resp.authToken) {
        Cookies.set('authToken', resp.authToken, {
          domain: axiosInstance.defaults.data['domain-app'],
        });
        Cookies.set('refreshToken', resp.refreshToken, {
          domain: axiosInstance.defaults.data['domain-app'],
        });
        axiosInstance.defaults.headers.common['X-Authorization'] =
          'Bearer ' + resp.authToken;
        //@ts-ignore
        originalRequest.headers['Content-Type'] = contentType;
        //@ts-ignore
        return axiosInstance(originalRequest);
      } else {
        removeAuthentication(
          axiosInstance.defaults.data['domain-app'] as string
        );
        window.location.href = axiosInstance.defaults.data['auth-url'] || '/';
      }
    } else {
      removeAuthentication(axiosInstance.defaults.data['domain-app'] as string);
      if (Cookies.get('authToken')) {
        window.location.href = axiosInstance.defaults.data['auth-url'] || '/';
      }
    }
  } else if (
    error.response?.status &&
    error.response?.status >= 500 &&
    //@ts-ignore
    !error.response?.data?.errorCode
  ) {
    const isApiNotEligibleShowAlert =
      error.response.config.url?.includes('api/sitemap.xml') ||
      error.response.config.url?.includes('api/wp') ||
      window?.location?.pathname?.includes('/order');
    if (!isApiNotEligibleShowAlert) {
      showGlobalAlert('error_maintenance');
    }
  } else if (error.response?.status === 400) {
    console.log('error request ', error);
    if (
      errorDataMessage.response?.data?.message ===
        'ERR_SUBSCRIPTION_NOT_ACTIVE_UNAUTHORIZED_ACCESS' &&
      !isEmpty(errorDataMessage.config?.data)
    ) {
      const modalStore = useModalStore;
      const modalData = modalStore.getState();
      modalStore.setState({
        ...modalData,
        modalName: 'modalSubscriptionFinished',
      });
    }
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  //@ts-ignore
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, (err) =>
    onResponseError(err, axiosInstance)
  );
  return axiosInstance;
}
