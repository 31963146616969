import { removeAuthentication, useModalStore } from '@navi-app/utils';
import Cookies from 'js-cookie';

export default function useUpdateVersion() {
  const { modalData, closeModal, modalName } = useModalStore();
  const isOpen = modalName === 'modal-update-version';

  const onUpdateVersion = async () => {
    if (modalData?.isLogin) {
      removeAuthentication(modalData?.domain);
      window?.location?.reload();
    } else {
      Cookies.set('latestVersion', modalData?.latestVersion || 'v1');
      window?.location?.reload();
    }
  };

  return {
    onUpdateVersion,
    closeModal,
    isOpen,
  };
}
