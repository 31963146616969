const {
  VITE_ENVIRONMENT,
  VITE_API_URL,
  VITE_MOBI_URL,
  VITE_OPTIMA_URL,
  VITE_BRAND_URL,
  VITE_AUTH_URL,
  VITE_TEMPLATE_UPLOAD_ORDER_URL,
  VITE_DOMAIN_APP,
  VITE_CSP_ALLOWED_URL,
  VITE_CSP_ALLOWED_SUB_URL,
  VITE_X_API_KEY_DEMO,
  VITE_RECAPTCHA_SITE_KEY,
  VITE_SENTRY_DSN,
  VITE_NOMINATIM_API_URL,
  VITE_GA_MEASUREMENT_ID,
} = import.meta.env;

const config = {
  ENV: VITE_ENVIRONMENT,
  API_URL: VITE_API_URL,
  IS_DEVELOPMENT_ENV: VITE_ENVIRONMENT === 'development',
  IS_PRODUCTION_ENV: VITE_ENVIRONMENT === 'production',
  MOBI_URL: VITE_MOBI_URL,
  OPTIMA_URL: VITE_OPTIMA_URL,
  BRAND_URL: VITE_BRAND_URL,
  AUTH_URL: VITE_AUTH_URL,
  TEMPLATE_UPLOAD_ORDER_URL: VITE_TEMPLATE_UPLOAD_ORDER_URL,
  DOMAIN_URL: VITE_DOMAIN_APP || '.mobitech.id',
  CSP_ALLOWED_URL: VITE_CSP_ALLOWED_URL,
  CSP_ALLOWED_SUB_URL: VITE_CSP_ALLOWED_SUB_URL,
  NOMINATIM_API_URL: VITE_NOMINATIM_API_URL,
  X_API_KEY_DEMO: VITE_X_API_KEY_DEMO,
  RECAPTCHA_SITE_KEY: VITE_RECAPTCHA_SITE_KEY,
  SENTRY_DSN: VITE_SENTRY_DSN,
  GA_MEASUREMENT_ID: VITE_GA_MEASUREMENT_ID,
};
export default config;
