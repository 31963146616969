/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Tag } from '@navi-app/ui';
import {
  apiAuthentication,
  isEmpty,
  removeAuthentication,
  useModalStore,
  useUserAccess,
  useWindowSize,
  withErrorBoundary,
} from '@navi-app/utils';
import {
  AppNavBar,
  NavItem,
  StyledPrimaryMenuContainer,
} from 'baseui/app-nav-bar';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulMenu } from 'baseui/menu';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import {
  LabelLarge,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { memo, ReactNode, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

export type TNavbar = {
  user?: {
    name: string;
    email: string;
    profilePicture: string;
  };
  config?: {
    mobiUrl?: string;
    optimaUrl?: string;
    domainApp?: string;
    apiUrl?: string;
    isDevelopmentEnv?: boolean;
    prefixPath?: string;
    tutorialUrl?: string;
  };
  isFixedTop?: boolean;
  actionHeader?: {
    label: string;
    info: {
      url: string;
      className?: string;
    };
  };
  mainItems?: NavItem[];
  setMainItems?: any;
  logoUrl?: string;
  customRightContent?: ReactNode;
  openSideBar?: boolean;
  setOpenSideBar?: (openSideBar: boolean) => void;
};

function Navbar({
  user,
  isFixedTop = true,
  mainItems,
  setMainItems,
  config,
  logoUrl,
  actionHeader,
  customRightContent,
  openSideBar,
  setOpenSideBar,
}: TNavbar) {
  const { action, getAccess, isUserVendor, userProfile } = useUserAccess({
    config: {
      apiUrl: config?.apiUrl || '',
      isDevelopmentEnvironment: config?.isDevelopmentEnv || false,
      domainApp: config?.domainApp || '',
    },
  });
  const { isPhoneSize } = useWindowSize();
  const { openModal } = useModalStore();

  const [expandedItem, setExpandedItem] = useState({
    itemId: '',
    title: '',
  });
  const [isAccountOpen, setIsAccountOpen] = useState(false);

  const toggleExpand = (item: any) => {
    setExpandedItem({
      itemId: item?.itemId,
      title: item?.title,
    });
  };

  // Get API Auth
  const apiAuth = apiAuthentication.authenticationAPI(config?.apiUrl || '');
  const mutationLogout = useMutation(apiAuth.logout);

  const userItems = [
    {
      label: 'Update Versi Terbaru',
      info: { id: 'updateVersion', type: 'user-menu' },
    },
    {
      label: 'Ubah Kata Sandi',
      info: { id: 'changePassword', type: 'user-menu' },
    },
    {
      label: 'Langganan',
      info: { id: 'subscription', type: 'user-menu' },
    },
    {
      label: 'Panduan Pengguna',
      info: { id: 'userGuide', type: 'user-menu' },
    },
    { label: 'Keluar', info: { id: 'signOut', type: 'user-menu' } },
  ];

  const logout = () => {
    localStorage.removeItem('new-user-storage');
    removeAuthentication(config?.domainApp);
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  function handleUserItemSelect(item: NavItem) {
    if (item.info?.id === 'signOut') {
      mutationLogout.mutate(null, {
        onSuccess: logout,
        onError: logout,
      });
    }

    if (item?.info?.id === 'userGuide') {
      openModal('modalTutorialVideo', { isFromNavbar: true });
    }

    if (item.info?.id === 'changePassword') {
      openModal('ModalChangePassword', {
        callback: logout,
      });
    }

    if (item.info?.id === 'updateVersion') {
      openModal('modal-update-version', {
        latestVersion: 'v1',
        isLogin: true,
        domain: config?.domainApp,
      });
    }

    if (item?.info?.id === 'subscription') {
      window.location.assign(`${config?.mobiUrl}/subscription`);
    }
  }

  const subscriptionTagContent: {
    label: string;
    kind: 'accent-solid' | 'secondary' | 'light-warning';
  } = useMemo(() => {
    switch (userProfile?.subscription?.type) {
      case 'basic':
        return {
          label: 'BASIC',
          kind: 'accent-solid',
        };
      case 'enterprise':
        return {
          label: 'ENTERPRISE',
          kind: 'secondary',
        };
      default:
        return {
          label: 'FREE',
          kind: 'light-warning',
        };
    }
  }, [userProfile?.subscription]);

  const title = (
    <div className="w-full flex justify-between items-center">
      {logoUrl ? (
        <img src={logoUrl} alt="logo" className="!h-9" />
      ) : (
        <LabelLarge>{userProfile?.organization?.name}</LabelLarge>
      )}
      {actionHeader && isPhoneSize && (
        <a href={actionHeader?.info?.url}>
          <Button size={SIZE.compact} className={actionHeader?.info?.className}>
            {actionHeader?.label}
          </Button>
        </a>
      )}
    </div>
  );

  const itemsMobile = mainItems?.map((item: any) => {
    return {
      title: item?.label,
      itemId: item?.info?.url,
      active: item?.active,
      subNav: item?.children?.map((child: any) => {
        return {
          title: child?.label?.props?.children,
          itemId: child?.info?.url,
          active: child?.active,
        };
      }),
    };
  });

  const buttonHeaderTitle = () => {
    if (!openSideBar && isAccountOpen) {
      return <ChevronLeftIcon className="w-8 h-8" />;
    } else if (openSideBar && !isAccountOpen) {
      return <XMarkIcon className="w-8 h-8" />;
    } else {
      return <Bars3Icon className="w-8 h-8" />;
    }
  };

  return isPhoneSize ? (
    <>
      {/* //HEADER start */}
      <div className="!sticky top-0 z-10 bg-white w-full flex justify-between items-center p-4 border-b border-solid border-[#E8E8E8]">
        <div className="flex">
          <Button
            kind={KIND.tertiary}
            onClick={() => {
              setOpenSideBar?.(!openSideBar);
              if (isAccountOpen) {
                setIsAccountOpen(!isAccountOpen);
              }
            }}
          >
            {buttonHeaderTitle()}
          </Button>
          <img
            onClick={() => (window.location.href = config?.mobiUrl + '/')}
            src={logoUrl}
            alt="logo"
            className="!h-10 pt-2 cursor-pointer"
          />
        </div>
        {!isUserVendor && (
          <Button
            size={SIZE.compact}
            className={actionHeader?.info?.className}
            onClick={() => {
              action(() => {
                window?.location?.assign(actionHeader?.info?.url || '/');
              }, 'orders');
            }}
          >
            {actionHeader?.label}
          </Button>
        )}
      </div>
      {/* //HEADER end */}
      {openSideBar && !isAccountOpen && (
        <div className="flex flex-col justify-between w-screen h-[88vh] z-[10] bg-white">
          <div className="w-full">
            {itemsMobile?.map((item: any) => (
              <div className="border-b border-solid w-full" key={item.itemId}>
                <div
                  className="flex justify-between items-center w-full p-6 "
                  onClick={() => toggleExpand(item)}
                >
                  <a href={item.itemId}>
                    <LabelMedium color={item.active ? '#1E64DC' : '#03222F'}>
                      {item.title}
                    </LabelMedium>
                  </a>
                  {item?.subNav?.length > 0 ? (
                    expandedItem === item.itemId ? (
                      <ChevronUpIcon className="w-10 h-10" />
                    ) : (
                      <ChevronDownIcon className="w-10 h-10" />
                    )
                  ) : null}
                </div>
                {expandedItem.itemId === item.itemId &&
                  expandedItem?.title === item?.title &&
                  item?.subNav?.length > 0 && (
                    <div className="pl-4">
                      {/* Placeholder for sub-navigation items */}
                      <div className="pl-4">
                        {item.subNav.map((subItem: any) => (
                          <div className="mb-6" key={subItem.itemId}>
                            <a href={subItem.itemId}>
                              <ParagraphMedium
                                color={subItem.active ? '#1E64DC' : '#03222F'}
                              >
                                {subItem.title}
                              </ParagraphMedium>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            ))}
            {!isUserVendor && (
              <div className="border-b border-solid w-full">
                <div className="flex justify-between items-center w-full p-6">
                  {customRightContent}
                </div>
              </div>
            )}
          </div>
          <div
            className="flex items-center justify-between m-6 p-4 border-[1px] border-solid border-[#CCCCCC] rounded-xl flex items-center"
            onClick={() => {
              setOpenSideBar?.(!openSideBar);
              setIsAccountOpen(!isAccountOpen);
            }}
          >
            <div className="flex items-center">
              <UserCircleIcon className="w-10 h-10 mr-2" />
              <LabelMedium>Akun</LabelMedium>
            </div>
            <ChevronRightIcon className="w-10 h-10" />
          </div>
        </div>
      )}
      {!openSideBar && isAccountOpen ? (
        <div className="w-screen h-screen bg-white absolute z-[5] bg-white">
          <div className="flex items-center m-6 border-b border-solid">
            <a href={config?.mobiUrl + '/user-profile'}>
              <div className="flex items-center mb-6">
                <UserCircleIcon className="w-10 h-10 mr-5" />
                <div>
                  <LabelMedium>{user?.name}</LabelMedium>
                  <ParagraphSmall>{user?.email}</ParagraphSmall>
                </div>
              </div>
            </a>
          </div>
          <div className="w-full">
            {userItems?.map((item: any) => (
              <div className="border-b border-solid w-full" key={item?.info.id}>
                <div
                  className="flex justify-between items-center w-full p-6"
                  onClick={() => {
                    setIsAccountOpen(!isAccountOpen);
                    handleUserItemSelect(item);
                  }}
                >
                  <LabelMedium color="#03222F">{item?.label}</LabelMedium>
                  {item?.info.id === 'signOut' ? (
                    <ArrowRightStartOnRectangleIcon className="w-10 h-10" />
                  ) : (
                    <ChevronRightIcon className="w-10 h-10" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  ) : (
    <AppNavBar
      title={title}
      mainItems={mainItems}
      userItems={userItems}
      onUserItemSelect={handleUserItemSelect}
      username={user?.name || ''}
      usernameSubtitle={
        <div
          className="w-52 lg:w-36 xl:w-36 truncate"
          title={user?.email || ''}
        >
          {user?.email || ''}
        </div>
      }
      userImgUrl={user?.profilePicture || ''}
      mapItemToNode={(item) => {
        // User dropdown menu
        if (item.info?.type === 'user-menu') {
          return (
            <div className="header-menu w-full fixed left-0 flex justify-between -mt-[10px] px-4">
              <div className="flex items-center gap-2">
                {item.label}{' '}
                {item?.info?.id === 'subscription' && (
                  <Tag
                    content={subscriptionTagContent?.label}
                    kind={subscriptionTagContent?.kind}
                  />
                )}
              </div>
              {item.info?.id === 'signOut' && (
                <ArrowRightStartOnRectangleIcon className="w-5 h-5" />
              )}
              {(item.info?.id === 'changePassword' ||
                item?.info?.id === 'subscription' ||
                item?.info?.id === 'userGuide') && (
                <ChevronRightIcon className="w-5 h-5" />
              )}
            </div>
          );
        }
        // Navbar menu
        if (!isEmpty(item.children)) {
          const isHasAccess = getAccess('orders');
          const items = item.children || [];
          return (
            <StatefulPopover
              focusLock
              placement={PLACEMENT.bottomLeft}
              overrides={{
                Body: {
                  style: { minWidth: '200px' },
                },
              }}
              content={({ close }) => (
                <StatefulMenu
                  items={items}
                  onItemSelect={({ item: subItem }) => {
                    if (
                      subItem.info?.url.includes('/fleet-monitoring') &&
                      !isHasAccess
                    ) {
                      return;
                    } else {
                      window.location.assign(subItem.info?.url || '/');
                    }
                  }}
                  overrides={{
                    List: {
                      style: { padding: 0 },
                      component: (props) => {
                        return props.children?.map((c: any) => (
                          <div>
                            {!isHasAccess &&
                            c.props?.item.info?.url === '/fleet-monitoring' ? (
                              c
                            ) : (
                              <a href={c.props?.item?.info?.url}>{c}</a>
                            )}
                          </div>
                        ));
                      },
                    },
                  }}
                />
              )}
            >
              <div className="text-base flex items-center justify-between h-[68px] cursor-pointer px-3">
                {item.label}
                <ChevronDownIcon className="w-5 h-5 ml-2" />
              </div>
            </StatefulPopover>
          );
        }
        if (item.info?.type === 'button' && !isUserVendor) {
          return (
            <Button
              size={SIZE.compact}
              className={item.info?.className}
              onClick={() => {
                action(() => {
                  window?.location?.assign(item?.info?.url || '/');
                }, 'orders');
              }}
            >
              {item.label}
            </Button>
          );
        }
        if (item.info?.type === 'check-delivery-price' && !isUserVendor) {
          return customRightContent;
        }
        return (
          <a href={item.info?.url} className="px-3">
            {item.label}
          </a>
        );
      }}
      overrides={{
        Root: {
          style: {
            position: isFixedTop ? 'sticky' : 'relative',
            top: 0,
            height: '68px',
            zIndex: 1,
            paddingInlineEnd: isPhoneSize
              ? '16px !important'
              : '40px !important',
            paddingInlineStart: isPhoneSize
              ? '16px !important'
              : '40px !important',
          },
        },
        PrimaryMenuContainer: {
          style: () => ({
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '24px',
          }),
          component: (props) => (
            <div className="flex w-full justify-between mr-4">
              <StyledPrimaryMenuContainer {...props}>
                {props.children}
              </StyledPrimaryMenuContainer>
              {!isUserVendor && customRightContent}
            </div>
          ),
        },
        MainMenuItem: {
          style: () => ({
            height: '68px',
            marginTop: '-18px',
            marginBottom: '-18px',
            borderBottomWidth: '4px',
            fontWeight: 500,
            marginLeft: '4px',
            marginRight: '4px',
          }),
        },
        SecondaryMenuContainer: {
          style: () => ({ display: 'none' }),
        },
        UserMenuProfileListItem: {
          style: { borderBottom: '1px solid #E8E8E8' },
          component: (props: any) => (
            <div className="flex flex-row items-center w-full pl-4">
              <div {...props}>{props.children}</div>
              {/* Hide if vendor */}
              {/* For vendor user */}
              {!isUserVendor && (
                <a href={config?.mobiUrl + '/user-profile'}>
                  <ChevronRightIcon className="w-5 h-5 cursor-pointer ml-1" />
                </a>
              )}
            </div>
          ),
        },
        AppName: {
          style: {
            width: isPhoneSize ? '100%' : '189px',
          },
        },
        UserMenuButton: {
          style: {
            backgroundColor: 'pink !important',
          },
        },
      }}
    />
  );
}

export default withErrorBoundary(memo(Navbar));
